import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: ProductState = {
    all: [],
    orderList: [],
    viewing: undefined,
    overview: {
        latest: [],
        outlet: [],
    },
};

const getters: GetterTree<ProductState, RootState> = {
    all(state: ProductState): Product[] {
        return state.all;
    },
    orderList(state: ProductState): Product[] {
        return state.orderList;
    },
    overview(state: ProductState): OverviewState {
        return state.overview;
    },
    viewing(state: ProductState): Product | undefined {
        return state.viewing;
    },
};

const mutations: MutationTree<ProductState> = {
    RESET(state: ProductState) {
        state.all = [];
        state.orderList = [];
        state.overview = {
            latest: [],
            outlet: [],
        };
        state.viewing = undefined;
    },

    ADD_MODEL(state: ProductState, payload: Product) {
        state.all.push(payload);
    },
    ADD_MODELS(state: ProductState, payload: Product[]) {
        state.all = [...state.all, ...payload];
    },
    SET_MODELS(state: ProductState, payload: Product[]) {
        state.all = payload;
    },

    ADD_ORDERLIST_MODEL(state: ProductState, payload: Product) {
        state.orderList.push(payload);
    },
    ADD_ORDERLIST_MODELS(state: ProductState, payload: Product[]) {
        state.orderList = [...state.orderList, ...payload];
    },
    SET_ORDERLIST_MODELS(state: ProductState, payload: Product[]) {
        state.orderList = payload;
    },

    SET_OVERVIEW(state: ProductState, payload: OverviewState) {
        state.overview = payload;
    },

    UPDATE_MODEL(state: ProductState, payload: Product) {
        const allIndex = state.all.findIndex((model: Product) => model.id === payload.id);
        const orderListIndex = state.orderList.findIndex((model: Product) => model.id === payload.id);

        if (allIndex === -1) {
            state.all.unshift(payload);
        } else {
            state.all[allIndex] = payload;
        }

        if (orderListIndex === -1) {
            state.orderList.unshift(payload);
        } else {
            state.orderList[orderListIndex] = payload;
        }
    },
    REMOVE_MODEL(state: ProductState, payload: Product) {
        state.all = state.all.filter((model: Product) => model.id !== payload.id);
        state.orderList = state.orderList.filter((model: Product) => model.id !== payload.id);
    },
    REMOVE_ORDERLIST_MODEL(state: ProductState, payload: Product) {
        state.orderList = state.orderList.filter((model: Product) => model.id !== payload.id);
    },
    SET_VIEWING(state: ProductState, payload: Product) {
        state.viewing = payload;
    },
};

const resource: string = 'products';

const actions: ActionTree<ProductState, RootState> = {
    clearViewing({ commit }) {
        commit('SET_VIEWING', undefined);
    },
    create({ commit, rootState }, payload: Product) {
        return rootState.api
            .post(`${resource}`, payload)
            .then((response: { data: Product }) => {
                commit('ADD_MODEL', response.data);

                commit('SET_VIEWING', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }, payload?: { after?: Array<string | number>; collection_slug?: string; exclude_collection?: number; no_set?: boolean; q?: string; per_page?: string; ids?: number[] }) {
        return rootState.api
            .get(
                `${resource}${payload != null && payload.q ? '?s[_score]=desc&' : '?'}s[product_no]=asc&s[id]=asc${
                    payload && payload.exclude_collection ? `&q[and][not][collections][]=${payload.exclude_collection}` : ''
                }${payload && payload.collection_slug ? `&q[and][collection_slugs][]=${payload.collection_slug}` : ''}${
                    payload != null && payload.q
                        ? `&q[and][or][name][]=like:*${payload.q}*&q[and][or][name][]=match:${payload.q}&q[and][or][product_no][]=match^10:${payload.q}&q[and][or][product_no][]=like^100:*${payload.q}*`
                        : ''
                }${
                    payload != null && payload.ids
                        ? payload.ids.map((id) => `&q[and][or][id][]=${id}`).join('')
                        : ''
                }${payload != null && payload.after ? `&after[]=${payload.after[0]}&after[]=${payload.after[1]}` : ''}&per_page=${payload != null && payload.per_page ? payload.per_page : '24'}`,
                { withCredentials: true },
            )
            .then((response: { data: Product }) => {
                if (payload && payload.hasOwnProperty('after') && payload.after != null) {
                    commit('ADD_MODELS', response.data);
                } else if (!payload || (payload && !payload.hasOwnProperty('no_set'))) {
                    commit('SET_MODELS', response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    orderList(
        { commit, rootState },
        payload: {
            company_slug: string;
            no_set?: boolean;
            collection_slug?: string;
            created_at_sort?: string;
            product_no_sort?: string;
            per_page?: string;
            after?: Array<string | number> | undefined;
            q?: string;
            ids?: number[];
        },
    ) {
        return rootState.api
            .get(
                `${resource}?q[and][collection_slugs][]=${payload.company_slug}${payload.collection_slug ? `&q[and][collection_slugs][]=${payload.collection_slug}` : ''}${
                    payload != null && payload.q ? `&q[and][or][name][]=like:*${payload.q}*&q[and][or][name][]=match:${payload.q}&q[and][or][product_no][]=like:*${payload.q}*` : ''
                }${payload != null && payload.q ? '&s[_score]=desc' : ''}${
                    payload.created_at_sort ? `&s[created_at]=${payload.created_at_sort}` : payload.product_no_sort ? `&s[product_no]=${payload.product_no_sort}` : '&s[product_no]=asc'
                }&s[id]=asc&per_page=${payload != null && payload.per_page ? payload.per_page : '24'}${payload.after ? `&after[]=${payload.after[0]}&after[]=${payload.after[1]}` : ''}`,
                {
                    withCredentials: true,
                },
            )
            .then((response: { data: Product }) => {
                if (payload.hasOwnProperty('after') && payload.after != null) {
                    commit('ADD_ORDERLIST_MODELS', response.data);
                } else if (!payload.hasOwnProperty('no_set')) {
                    commit('SET_ORDERLIST_MODELS', response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    setOrderList({ commit, rootState }, payload: Product[]) {
        commit('SET_ORDERLIST_MODELS', payload);
    },
    orderListById(
        { commit, rootState },
        payload: { id: number; collection_slug?: string; created_at_sort?: string; product_no_sort?: string; after?: Array<string | number>; q?: string; ids?: number[]; no_set?: boolean },
    ) {
        return rootState.api
            .get(
                `${resource}?q[and][collections][]=${payload.id}${payload.collection_slug ? `&q[and][collection_slugs][]=${payload.collection_slug}` : ''}${
                    payload != null && payload.q ? `&q[and][or][name][]=like:*${payload.q}*&q[and][or][name][]=match:${payload.q}&q[and][or][product_no][]=like:*${payload.q}*` : ''
                }${payload != null && payload.q ? '&s[_score]=desc' : ''}${
                    payload.created_at_sort ? `&s[created_at]=${payload.created_at_sort}` : payload.product_no_sort ? `&s[product_no]=${payload.product_no_sort}` : '&s[product_no]=asc'
                }&s[id]=asc&per_page=24${payload.after ? `&after[]=${payload.after[0]}&after[]=${payload.after[1]}` : ''}`,
                { withCredentials: true },
            )
            .then((response: { data: Product }) => {
                if (payload && payload.hasOwnProperty('after') && payload.after != null) {
                    commit('ADD_ORDERLIST_MODELS', response.data);
                } else if (payload && !payload.hasOwnProperty('no_set')) {
                    commit('SET_ORDERLIST_MODELS', response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    overview({ commit, rootState }, payload: { company_slug: string }) {
        const overview: OverviewState = {
            latest: [],
            outlet: [],
        };

        const getLatest = () => {
            return new Promise((resolve, reject) => {
                rootState.api
                    .get(`${resource}?per_page=6&q[collection_slugs]=${payload.company_slug}`, { withCredentials: true })
                    .then((response: { data: Product[] }) => {
                        overview.latest = response.data;
                        resolve(response.data);
                    })
                    .catch((e: ErrorResponse) => {
                        reject(e);
                    });
            });
        };

        const getOutlet = () => {
            return new Promise((resolve, reject) => {
                rootState.api
                    .get(`${resource}?per_page=6&q[and][collection_slugs][]=${payload.company_slug}&q[and][collection_slugs][]=4-uitverkoop`, { withCredentials: true })
                    .then((response: { data: Product[] }) => {
                        overview.outlet = response.data;
                        resolve(response.data);
                    })
                    .catch((e: ErrorResponse) => {
                        reject(e);
                    });
            });
        };

        const promises = [getLatest(), getOutlet()];

        Promise.all(promises)
            .then(() => {
                commit('SET_OVERVIEW', overview);
                return Promise.resolve(overview);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: { slug: string; no_set?: boolean }) {
        return rootState.api
            .get(`${resource}/${payload.slug}`, { withCredentials: true })
            .then((response: { data: Product }) => {
                if (!payload.hasOwnProperty('no_set')) {
                    commit('SET_VIEWING', response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, payload: { product: Product; removeFromOrderlist?: boolean }) {
        return rootState.api
            .put(`${resource}/${payload.product.id}`, payload.product, { withCredentials: true })
            .then((response: { data: Product }) => {
                if (payload && payload.removeFromOrderlist) {
                    commit('REMOVE_ORDERLIST_MODEL', response.data);
                } else {
                    commit('UPDATE_MODEL', response.data);
                    // commit('SET_VIEWING', response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    patch({ commit, rootState }, payload: { id: number; collections: Array<{ id: number; action: string }>; removeFromOrderlist?: boolean }) {
        return rootState.api
            .patch(`${resource}/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Product }) => {
                if (payload && payload.removeFromOrderlist) {
                    commit('REMOVE_ORDERLIST_MODEL', response.data);
                } else {
                    commit('UPDATE_MODEL', response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .delete(`${resource}/${payload.id}`, { withCredentials: true })
            .then((response: { data: Product }) => {
                commit('REMOVE_MODEL', payload);

                return Promise.resolve(payload);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
