






































































import { Getter } from 'vuex-class';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import ProductModal from '@/components/product/ProductModal.vue';

@Component({
    components: {
        PartHeader: () => import('../parts/Header.vue'),
        PartFooter: () => import('../parts/Footer.vue'),
        PartSidebar: () => import('../parts/Sidebar.vue'),
        PartSidebarToggle: () => import('../parts/SidebarToggle.vue'),
        ContactSlideout: () => import('@/components/contact/ContactSlideout.vue'),
    },
})
export default class Base extends Vue {
    @Prop({ default: false }) hasHeader!: boolean;

    @Prop({ default: false }) hasFooter!: boolean;

    @Prop({ default: false }) hasSidebar!: boolean;

    @Prop({ default: 100 }) headerHeight!: number;

    @Prop({ default: 100 }) footerHeight!: number;

    @Prop({ default: 400 }) sidebarWidth!: number;

    @Getter('app/contactSlideoutVisible') contactSlideoutVisible!: boolean;
    @Getter('product/viewing') product!: Product;
    @Getter('user/isAdmin') isAdmin!: boolean;

    sidebarActive: boolean = false;

    get hasSidebarPart() {
        return !!this.$slots.sidebar || this.hasSidebar;
    }

    get sidebarIsVisible() {
        return this.hasSidebarPart && this.sidebarActive;
    }

    @Watch('product')
    productChanged(product: Product) {
        if (product != null && product.variants.length) {
            const variant = product.variants[0];
            this.$modal.show(ProductModal, { variant, product }, { adaptive: true, width: '750', height: 'auto' });
        }
    }
}
