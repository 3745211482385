import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: OrderState = {
    all: [],
    viewing: undefined,
};

const getters: GetterTree<OrderState, RootState> = {
    all(state: OrderState): Order[] {
        return state.all;
    },
};

const mutations: MutationTree<OrderState> = {
    RESET(state: OrderState) {
        state.all = [];
        state.viewing = undefined;
    },
    UPDATE_MODEL(state: OrderState, payload: Order) {
        const index = state.all.findIndex((model: Order) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            state.all[index] = payload;
        }
    },
    SET_MODELS(state: OrderState, payload: Order[]) {
        state.all = payload;
    },
    ADD_MODELS(state: OrderState, payload: Order[]) {
        state.all = [...state.all, ...payload];
    },
    SET_VIEWING(state: OrderState, payload: Order) {
        state.viewing = payload;
    },
};

const resource: string = 'orders';

const actions: ActionTree<OrderState, RootState> = {
    index({ commit, rootState }, payload?: { companyId?: number | null; after?: Array<string | number>; q?: string; ids?: number[] }) {
        return rootState.api
            .get(
                `${resource}?per_page=15&s[created_at]=desc&s[id]=desc${payload && payload.after ? `&after[]=${payload.after[0]}&after[]=${payload.after[1]}` : ''}${
                    payload && payload.companyId ? `&q[and][company_id]=${payload.companyId}` : ''
                }${payload && payload.q ? `&q[and][or][id]=${payload.q}&q[and][or][own_reference]=like:*${payload.q}*` : ''}`,
                { withCredentials: true },
            )
            .then((response: { data: Order }) => {
                if (payload && payload.hasOwnProperty('after') && payload.after != null) {
                    commit('ADD_MODELS', response.data);
                } else {
                    commit('SET_MODELS', response.data);
                }
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    mostRecent({ commit, rootState }, companyId: number) {
        return rootState.api
            .get(`${resource}?q[company_id]=${companyId}&s[created_at]=desc&per_page=4`, { withCredentials: true })
            .then((response: { data: Order }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: ReadOrderPayload) {
        return rootState.api
            .get(`${resource}/${payload.id}`)
            .then((response: { data: Order }) => {
                commit('UPDATE_MODEL', response.data);

                commit('SET_VIEWING', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
