import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const localStorageAuth: string | null = localStorage.getItem(`${process.env.VUE_APP_NAME}_auth`);
const localStoragePermissions: string | null = localStorage.getItem(`${process.env.VUE_APP_NAME}_permissions`);
const initialState: UserState = {
    auth: localStorageAuth ? JSON.parse(localStorageAuth) : undefined,
    permissions: localStoragePermissions ? JSON.parse(localStoragePermissions) : undefined,
    role: undefined,
};

const getters: GetterTree<UserState, RootState> = {
    user(state: UserState): Auth | undefined {
        return state.auth;
    },
    permissions(state: UserState): string[] | undefined {
        return state.permissions;
    },
    isAdmin(state: UserState): boolean {
        return state.permissions != null && state.permissions.includes('invite.index');
    },
    isSupervisor(state: UserState, siblingGetters: any): boolean {
        return state.permissions != null && !siblingGetters.isAdmin && state.permissions.includes('company.index');
    },
    isUser(state: UserState, siblingGetters: any): boolean {
        return state.permissions != null && !siblingGetters.isAdmin && !siblingGetters.isSupervisor;
    },
};

const mutations: MutationTree<UserState> = {
    RESET(state: UserState) {
        localStorage.clear();
        state.auth = undefined;
        state.permissions = undefined;
        state.role = undefined;
    },
    SET_AUTH(state: UserState, payload: Auth) {
        state.auth = payload;
    },
    STORE_AUTH(state: UserState, payload: Auth) {
        localStorage.setItem(`${process.env.VUE_APP_NAME}_auth`, JSON.stringify(payload));
    },
    SET_PERMISSIONS(state: UserState, payload: string[]) {
        state.permissions = payload;
    },
    STORE_PERMISSIONS(state: UserState, payload: Auth) {
        localStorage.setItem(`${process.env.VUE_APP_NAME}_permissions`, JSON.stringify(payload));
    },
    SET_ROLE(state: UserState, payload: string[]) {
        state.role = payload;
    },
};

const actions: ActionTree<UserState, RootState> = {
    login({ commit, rootState }, payload: LoginPayload) {
        return rootState.api
            .post('login', payload, { withCredentials: true })
            .then((response: { data: Auth }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    emailConfirmation({ commit, rootState }, payload: EmailConfirmationPayload) {
        return rootState.api
            .post('email/confirm', payload, { withCredentials: true })
            .then((response: { data: Auth }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    forgotPassword({ commit, rootState }, payload: ForgotPasswordPayload) {
        return rootState.api
            .post('password/forgot', payload)
            .then((response: { data: Auth }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    resetPassword({ commit, rootState }, payload: ResetPasswordPayload) {
        return rootState.api
            .post('password/reset', payload)
            .then((response: { data: Auth }) => {
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    register({ commit, rootState }, payload: RegisterPayload) {
        return rootState.api
            .post('register', payload)
            .then((response: { data: Auth }) => {
                commit('SET_AUTH', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    me({ commit, rootState, state }, dontUseStorage: boolean | undefined) {
        if (state.auth && !dontUseStorage) {
            return new Promise((resolve) => resolve(state.auth));
        }

        return rootState.api
            .get('me', { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('SET_AUTH', response.data);

                commit('STORE_AUTH', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState, state }, payload: RegisterPayload) {
        return rootState.api
            .get('me', { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('SET_AUTH', response.data);

                commit('STORE_AUTH', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    permissions({ commit, rootState, state }, dontUseStorage: boolean | undefined) {
        if (state.permissions && !dontUseStorage) {
            return new Promise((resolve) => resolve(state.permissions));
        }

        return rootState.api
            .get(`accounts/${state.auth?.id}/permissions`, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('SET_PERMISSIONS', response.data);

                commit('STORE_PERMISSIONS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    role({ commit, rootState, state }, accountId ) {
        return rootState.api
            .get(`roles/${accountId}`, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('SET_ROLE', response.data);
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    logout({ commit, rootState }) {
        return rootState.api
            .post('logout', {}, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('RESET');

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
