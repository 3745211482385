import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: CollectionState = {
    all: [],
    viewing: undefined,
};

const getters: GetterTree<CollectionState, RootState> = {
    all(state: CollectionState): Collection[] {
        return state.all;
    },
    viewing(state: CollectionState): Collection | undefined {
        return state.viewing;
    },
};

const mutations: MutationTree<CollectionState> = {
    RESET(state: CollectionState) {
        state.all = [];
        state.viewing = undefined;
    },
    ADD_MODEL(state: CollectionState, payload: Collection) {
        state.all.push(payload);
    },
    SET_MODELS(state: CollectionState, payload: Collection[]) {
        state.all = payload;
    },
    UPDATE_MODEL(state: CollectionState, payload: Collection) {
        const index = state.all.findIndex((model: Collection) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            state.all[index] = payload;
        }
    },
    REMOVE_MODEL(state: CollectionState, payload: Collection) {
        state.all = state.all.filter((model: Collection) => model.id !== payload.id);
    },
    SET_VIEWING(state: CollectionState, payload: Collection) {
        state.viewing = payload;
    },
};

const resource: string = 'collections';

const actions: ActionTree<CollectionState, RootState> = {
    clearViewing({ commit }) {
        commit('SET_VIEWING', undefined);
    },
    setViewing({ commit, rootState }, payload: Collection) {
        commit('SET_VIEWING', payload);
    },
    create({ commit, rootState }, payload: Collection) {
        return rootState.api
            .post(`${resource}`, payload, { withCredentials: true })
            .then((response: { data: Collection }) => {
                commit('ADD_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }) {
        return rootState.api
            .get(`${resource}`, { withCredentials: true })
            .then((response: { data: Collection }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexCategories({ commit, rootState }) {
        return rootState.api
            .get(`${resource}?q[or][slug][]=6-paint&q[or][slug][]=7-non-paint&q[or][slug][]=8-readymix&q[or][slug][]=10-spuitbussen`, { withCredentials: true })
            .then((response: { data: Collection }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: { id: number; no_set?: boolean }) {
        return rootState.api
            .get(`${resource}/${payload.id}`, { withCredentials: true })
            .then((response: { data: Collection }) => {
                commit('UPDATE_MODEL', response.data);

                if (!payload.hasOwnProperty('no_set')) {
                    commit('SET_VIEWING', response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, payload: Collection) {
        return rootState.api
            .put(`${resource}/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Collection }) => {
                commit('UPDATE_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    patch({ commit, rootState }, payload: { id: number; products: Array<{ id: number; action: string }> }) {
        return rootState.api
            .patch(`${resource}/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Collection }) => {
                commit('UPDATE_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .delete(`${resource}/${payload.id}`, { withCredentials: true })
            .then((response: { data: Collection }) => {
                commit('REMOVE_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
