import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

interface Messages {
    [key: string]: any;
}

export const availableLanguages = ['nl'];

let preferredLang = localStorage.getItem(`${process.env.VUE_APP_NAME}_LANG`) || 'nl';

preferredLang = preferredLang.substring(0, 2);
if (!availableLanguages.includes(preferredLang)) {
    preferredLang = 'nl';
}

const messages: Messages = {};
messages[preferredLang] = getMessages(preferredLang);
export const i18n = new VueI18n({
    locale: preferredLang,
    fallbackLocale: preferredLang,
    messages,
});
const loadedLanguages: string[] = [];

function setI18nLanguage(lang: string) {
    i18n.locale = lang;
    const el: HTMLElement | null = document.querySelector('html');
    if (el) {
        el.setAttribute('lang', lang);
    }
    localStorage.setItem(`${process.env.VUE_APP_NAME}_LANG`, lang);
    return lang;
}

function getMessages(lang: string) {
    let requireModule: any | null = null;
    if (lang === 'nl') {
        requireModule = require.context('@/localization/nl/.', false, /\.json$/);
    }

    const localMessages: Messages = [];
    localMessages[lang] = {};
    if (requireModule) {
        requireModule.keys().forEach((fileName: string) => {
            const key = fileName.replace(/(\.\/|\.json)/g, '');
            localMessages[lang][key] = requireModule(fileName);
        });
    }

    return {
        ...require(`@/localization/${lang}.json`),
        ...localMessages[lang],
    };
}

export function loadLanguageAsync(lang: string) {
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    i18n.setLocaleMessage(lang, getMessages(lang));
    loadedLanguages.push(lang);
    return Promise.resolve(setI18nLanguage(lang));
}
