import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { CreateElement } from 'vue/types/umd';
import { i18n } from '@/localization';
import '@/registerServiceWorker';
import '@/layouts/index';

import '@/assets/svg/index';

import '@/assets/styles/app.scss';

// Lazysizes

import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

import VueLazyload from 'vue-lazyload';
Vue.use(VueLazyload);

import vmodal from 'vue-js-modal';
Vue.use(vmodal);

import Toasted from 'vue-toasted';
Vue.use(Toasted);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

// @ts-ignore
import vClickOutside from 'v-click-outside';
Vue.use(vClickOutside);

// @ts-ignore
import VAnimateCss from 'v-animate-css';
Vue.use(VAnimateCss);

Vue.config.productionTip = false;

new Vue({
    router,
    store,
    i18n,
    render: (h: CreateElement) => h(App),
    data: () => ({
        breakpoints: ['xs'],
    }),
    mounted() {
        // @ts-ignore
        this.init();
        const self = this;
        // @ts-ignore
        window.addEventListener('resize', () => self.init());
        // @ts-ignore
        window.addEventListener('orientationchange', () => self.init());
    },
    methods: {
        init() {
            const vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            if (window.outerWidth >= 576) {
                this.breakpoints = ['xs', 'sm'];
            }
            if (window.outerWidth >= 768) {
                this.breakpoints = ['xs', 'sm', 'md'];
            }
            if (window.outerWidth >= 1024) {
                this.breakpoints = ['xs', 'sm', 'md', 'lg'];
            }
            if (window.outerWidth >= 1280) {
                this.breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];
            }
        },
    },
    computed: {
        isMobile() {
            return this.breakpoints.includes('xs') && !this.breakpoints.includes('md');
        },
        isDesktop() {
            return this.breakpoints.includes('md');
        },
    },
}).$mount('#app');
