import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: CompanyState = {
    all: [],
    viewing: undefined,
    cart: {
        products: [],
    },
};

const getters: GetterTree<CompanyState, RootState> = {
    all(state: CompanyState): Company[] {
        return state.all;
    },
    viewing(state: CompanyState): Company | undefined {
        return state.viewing;
    },
    cart(state: CompanyState): Cart | undefined {
        return state.cart;
    },
};

const mutations: MutationTree<CompanyState> = {
    RESET(state: CompanyState) {
        state.all = [];
        state.viewing = undefined;
        state.cart = {
            products: [],
        };
    },
    ADD_MODEL(state: CompanyState, payload: Company) {
        state.all.push(payload);
    },
    SET_MODELS(state: CompanyState, payload: Company[]) {
        state.all = payload;
    },
    ADD_MODELS(state: CompanyState, payload: Company[]) {
        state.all = [...state.all, ...payload];
    },
    UPDATE_MODEL(state: CompanyState, payload: Company) {
        const index = state.all.findIndex((model: Company) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            state.all[index] = payload;
        }
    },
    REMOVE_MODEL(state: CompanyState, payload: Company) {
        state.all = state.all.filter((model: Company) => model.id !== payload.id);
    },
    SET_VIEWING(state: CompanyState, payload: Company) {
        state.viewing = payload;
    },
    SET_CART(state: CompanyState, payload: Cart) {
        state.cart = payload;
    },
};

const resource: string = 'companies';

const actions: ActionTree<CompanyState, RootState> = {
    clearViewing({ commit }) {
        commit('SET_VIEWING', undefined);
    },
    create({ commit, rootState }, payload: Company) {
        return rootState.api
            .post(`${resource}`, payload, { withCredentials: true })
            .then((response: { data: Company }) => {
                commit('ADD_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }, payload?: { after?: Array<string | number>; q?: string; per_page?: string; status?: string; deliveryDay?: string }) {
        return rootState.api
            .get(
                `${resource}?per_page=${payload != null && payload.per_page ? payload.per_page : '15'}${payload != null && payload.q ? '&s[_score]=desc' : ''}&s[status]=asc&s[last_ordered_at]=desc&s[id]=desc${
                    payload != null && payload.q ? `&q[and][or][name][]=like:*${payload.q}*&q[and][or][name][]=match:${payload.q}` : ''
                }${payload != null && payload.status ? `&q[and][status]=${payload.status}` : '&q[and][not][status]=archived'}${
                    payload != null && payload.deliveryDay ? `&q[and][delivery_days][]=${payload.deliveryDay}` : ''
                }${payload != null && payload.after ? `&after[]=${payload.after[0]}&after[]=${payload.after[1]}&after[]=${payload.after[2]}` : ''}`,
                { withCredentials: true },
            )
            .then((response: { data: Company }) => {
                if (payload && payload.hasOwnProperty('after') && payload.after != null) {
                    commit('ADD_MODELS', response.data);
                } else {
                    commit('SET_MODELS', response.data);
                }
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .get(`${resource}/${payload.id}`, { withCredentials: true })
            .then((response: { data: Company }) => {
                commit('UPDATE_MODEL', response.data);

                commit('SET_VIEWING', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, payload: Company) {
        return rootState.api
            .put(`${resource}/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Company }) => {
                commit('UPDATE_MODEL', response.data);

                commit('SET_VIEWING', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .delete(`${resource}/${payload.id}`, { withCredentials: true })
            .then((response: { data: Company }) => {
                commit('REMOVE_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    readCart({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .get(`${resource}/${payload.id}/cart`, { withCredentials: true })
            .then((response: { data: Cart }) => {
                commit('SET_CART', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    updateCart({ commit, rootState }, payload: CartPutPayload) {
        return rootState.api
            .put(`${resource}/${payload.company_id}/cart`, payload, { withCredentials: true })
            .then((response: { data: Cart }) => {
                commit('SET_CART', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    patchCart({ commit, rootState }, payload: CartPatchPayload) {
        return rootState.api
            .patch(`${resource}/${payload.company_id}/cart`, payload, { withCredentials: true })
            .then((response: { data: Cart }) => {
                commit('SET_CART', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    createOrder({ commit, rootState, state }, payload: CreateOrderPayload) {
        return rootState.api
            .post(`${resource}/${state.viewing?.id}/orders`, payload, { withCredentials: true })
            .then((response: { data: Order }) => {
                // commit('UPDATE_MODEL', response.data);

                // commit('SET_VIEWING', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    inviteUser({ commit, rootState, state }, payload: InvitePayload) {
        return rootState.api
            .post('invites', payload, { withCredentials: true })
            .then((response: { data: Order }) => {
                // commit('UPDATE_MODEL', response.data);

                // commit('SET_VIEWING', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
