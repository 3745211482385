import Vue from 'vue';
import { Getter, Action } from 'vuex-class';
import { Component, Prop, Watch, Emit } from 'vue-property-decorator';

@Component
export class CartHelper extends Vue {
    @Getter('company/cart') cart!: Cart;
    @Getter('company/viewing') company!: Company;
    @Getter('user/user') user!: Auth;

    @Action('company/updateCart') updateCart!: (payload?: CartPutPayload) => Promise<any>;
    @Action('company/patchCart') patchCart!: (payload?: CartPatchPayload) => Promise<any>;

    @Prop() variant!: ProductVariant;

    get productsInCart() {
        if (this.variant != null) {
            return this.cart?.products.filter((p) => {
                return p.variant_id === this.variant.id;
            });
        }
        return null;
    }

    addProductToCart(amount: number, additionalInfo: string, variant: ProductVariant) {
        const companyId = this.company != null ? this.company.id : this.user.company_id != null ? this.user.company_id : null;

        if (companyId != null && variant != null && variant.id != null) {
            const self = this;
            const payload = {
                company_id: companyId,
                products: [
                    {
                        variant_id: variant.id,
                        quantity: parseFloat(amount as unknown as string),
                        additional_info: additionalInfo,
                        action: 'add',
                    },
                ],
            };

            this.patchCart(payload).then(
                () => {
                    self.showModal('Product is toegevoegd aan je bestelling.');
                },
                (err: ErrorResponse) => {
                    // console.log(err);
                },
            );
        }
    }

    deleteProductFromCart(variant: ProductVariant, additionalInfo: string, quantity: number) {
        if (this.productsInCart && this.company != null && this.company.id != null && variant != null && variant.id != null) {
            const self = this;
            const payload = {
                company_id: this.company.id,
                products: [
                    {
                        variant_id: variant.id,
                        quantity: quantity ? quantity : this.productsInCart.length === 1 ? this.productsInCart[0].quantity : 0,
                        action: 'remove',
                        additional_info: additionalInfo,
                    },
                ],
            };

            this.patchCart(payload).then(
                () => {
                    self.showModal('Product is verwijderd uit je bestelling.');
                },
                (err: ErrorResponse) => {
                    // console.log(err);
                },
            );
        }
    }

    showModal(message: string) {
        this.$toasted.show(message, {
            position: 'bottom-left',
            duration: 5000,
            action: {
                text: '',
                class: 'close',
                onClick: (e, toastObject) => {
                    toastObject.goAway(0);
                },
            },
        });
    }
}
