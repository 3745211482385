import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: SearchState = {
    all: {
        products: [],
        collections: [],
        orders: [],
    },
    products: [],
    collections: [],
    orders: [],
};

const getters: GetterTree<SearchState, RootState> = {
    all(state: SearchState): Search {
        return state.all;
    },
    products(state: SearchState): Product[] {
        return state.products;
    },
    collections(state: SearchState): Collection[] {
        return state.collections;
    },
    orders(state: SearchState): Order[] {
        return state.orders;
    },
};

const mutations: MutationTree<SearchState> = {
    RESET(state: SearchState) {
        state.all = {
            products: [],
            collections: [],
            orders: [],
        };
        state.products = [];
        state.collections = [];
        state.orders = [];
    },
    SET_MODELS(state: SearchState, payload: Search) {
        state.all = payload;
        state.products = payload.products;
        state.collections = payload.collections;
        state.orders = payload.orders;
    },
};

const actions: ActionTree<SearchState, RootState> = {
    index({ commit, rootState }, payload: { product?: string }) {
        return rootState.api.get(`search`, { params: payload })
            .then((response: { data: Search }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
