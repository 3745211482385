import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: DiscountState = {
    all: [],
    viewing: undefined,
};

const getters: GetterTree<DiscountState, RootState> = {
    all(state: DiscountState): Discount[] {
        return state.all;
    },
};

const mutations: MutationTree<DiscountState> = {
    RESET(state: DiscountState) {
        state.all = [];
        state.viewing = undefined;
    },
    ADD_MODEL(state: DiscountState, payload: Discount) {
        state.all.push(payload);
    },
    SET_MODELS(state: DiscountState, payload: Discount[]) {
        state.all = payload;
    },
    UPDATE_MODEL(state: DiscountState, payload: Discount) {
        const index = state.all.findIndex((model: Discount) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            state.all[index] = payload;
        }
    },
    REMOVE_MODEL(state: DiscountState, payload: Discount) {
        state.all = state.all.filter((model: Discount) => model.id !== payload.id);
    },
    SET_VIEWING(state: DiscountState, payload: Discount) {
        state.viewing = payload;
    },
};

const resource: string = 'discounts';

const actions: ActionTree<DiscountState, RootState> = {
    create({ commit, rootState }, payload: CreateDiscountPayload) {
        return rootState.api.post(`${resource}`, payload)
            .then((response: { data: Discount }) => {
                commit('ADD_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }) {
        return rootState.api.get(`${resource}`)
            .then((response: { data: Discount }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: ReadDiscountPayload) {
        return rootState.api.get(`${resource}/${payload.id}`)
            .then((response: { data: Discount }) => {
                commit('UPDATE_MODEL', response.data);

                commit('SET_VIEWING', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, payload: UpdateDiscountPayload) {
        return rootState.api.put(`${resource}/${payload.id}`, payload)
            .then((response: { data: Discount }) => {
                commit('UPDATE_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: DeleteDiscountPayload) {
        return rootState.api.delete(`${resource}/${payload.id}`)
            .then((response: { data: Discount }) => {
                commit('REMOVE_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
