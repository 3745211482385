import Vue from 'vue';
import { Component, Prop, Watch, Emit } from 'vue-property-decorator';

@Component
export class ProductHelper extends Vue {
    @Prop() product!: Product;
    activeVariant: ProductVariant | null = null;

    mounted() {
        if (this.product != null && this.product.variants.length) {
            this.activeVariant = this.product.variants[0];
        }
    }

    get firstImage(): string {
        let image = '';
        if (this.product.media != null && this.product.media.length > 0) {
            this.product.media.forEach((m: ProductImage) => {
                // @ts-ignore
                if (m.type.indexOf('link') < 0 && image === '') {
                    image = m.path;
                }
            });
        } else {
            return require('@/assets/images/product-image-placeholder.png');
        }
        return image;
    }
}
