import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: LogState = {
    all: [],
    viewing: undefined,
};

const getters: GetterTree<LogState, RootState> = {
    all(state: LogState): Log[] {
        return state.all;
    },
};

const mutations: MutationTree<LogState> = {
    RESET(state: LogState) {
        state.all = [];
        state.viewing = undefined;
    },
    UPDATE_MODEL(state: LogState, payload: Log) {
        const index = state.all.findIndex((model: Log) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            state.all[index] = payload;
        }
    },
    SET_MODELS(state: LogState, payload: Log[]) {
        state.all = payload;
    },
    SET_VIEWING(state: LogState, payload: Log) {
        state.viewing = payload;
    },
};

const resource: string = 'logs';

const actions: ActionTree<LogState, RootState> = {
    index({ commit, rootState }) {
        return rootState.api.get(`${resource}`)
            .then((response: { data: Log }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: ReadLogPayload) {
        return rootState.api.get(`${resource}/${payload.id}`)
            .then((response: { data: Log }) => {
                commit('UPDATE_MODEL', response.data);

                commit('SET_VIEWING', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
