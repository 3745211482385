










































































































































import { Action } from 'vuex-class';
import { Component, Mixins } from 'vue-property-decorator';
import { ProductHelper } from './ProductHelper';
import { CartHelper } from '@/mixins/CartHelper';

import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { ValidationObserver } from 'vee-validate';

interface Rules {
    [key: string]: any;
}
const customRules: Rules = rules;
Object.keys(customRules).forEach((rule: string) => {
    extend(rule, customRules[rule]);
});

for (const [rule, validation] of Object.entries(rules)) {
    extend(rule, { ...validation });
}

@Component({
    components: {
        ValidationObserver,
        ModalBase: () => import('@/components/elements/Modal.vue'),
        ElButton: () => import('@/components/elements/Button.vue'),
        ElInput: () => import('@/components/forms/Input.vue'),
    },
})
export default class ProductModal extends Mixins(ProductHelper, CartHelper) {
    @Action('product/clearViewing') clearViewing!: () => void;

    addAmount: number = 1;
    colorCode: string = '';

    increaseAmount() {
        this.addAmount = parseFloat((this.addAmount as unknown) as string);
        if (this.decimalsAllowed) {
            this.addAmount += 0.1;
            this.addAmount = parseFloat(this.addAmount.toFixed(1));
        } else {
            this.addAmount++;
        }
    }

    decreaseAmount() {
        this.addAmount = parseFloat((this.addAmount as unknown) as string);
        if (this.decimalsAllowed) {
            if (this.addAmount > 0.1) {
                this.addAmount -= 0.1;
                this.addAmount = parseFloat(this.addAmount.toFixed(1));
            }
        } else {
            if (this.addAmount > 1) {
                this.addAmount--;
            }
        }
    }

    doCopy() {
        const self = this;
        this.$copyText(`${window.location.origin}/producten#${this.product.slug}`).then((e) => {
            self.showModal('Link gekopiëerd!');
        });
    }

    disallowDecimals(e: InputEvent) {
        if (!this.decimalsAllowed) {
            // @ts-ignore
            e.target.value = Math.round(e.target.value.replace(/\D/g, ''));
            this.addAmount = ((e.target as HTMLInputElement).value as unknown) as number;
        }
    }

    destroyed() {
        this.clearViewing();
    }

    get isReadyMixOrSpraypaint() {
        if (this.product) {
            return this.product.collection_slugs.includes('8-readymix') || this.product.collection_slugs.includes('10-spuitbussen');
        }
        return false;
    }

    get decimalsAllowed() {
        return this.product && this.product.decimal_quantity && this.product.decimal_quantity === true;
    }
}
