import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: AppState = {
    contactSlideoutVisible: false,
};

const getters: GetterTree<AppState, RootState> = {
    contactSlideoutVisible(state: AppState): boolean {
        return state.contactSlideoutVisible;
    },
};

const mutations: MutationTree<AppState> = {
    RESET(state: AppState) {
        state.contactSlideoutVisible = false;
    },
    SET_CONTACT_SLIDEOUT_VISIBLE(state: AppState, payload: boolean) {
        state.contactSlideoutVisible = payload;
    },
};

const actions: ActionTree<AppState, RootState> = {
    updateContactSlideoutVisible({ commit, rootState }, payload: boolean) {
        commit('SET_CONTACT_SLIDEOUT_VISIBLE', payload);
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
