import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store/index';
import { CreateElement } from 'vue/types/umd';

Vue.use(Router);

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,

    /*
     * | ------------------------
     * | Simulate native-like scroll behavior when navigating to a new
     * | route and using back/forward buttons.
     * | ------------------------
     * |
     */
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },

    /*
     * | ------------------------
     * | All router page routes
     * | Contains all routes and their matching components
     * | ------------------------
     * |
     */
    routes: [
        {
            path: '/',
            name: 'home',
            beforeEnter: guardAuth,
            meta: {
                group: ['guest', 'auth'],
            },
        },
        {
            path: '/docs',
            name: 'docs',
            beforeEnter: guardAuth,
            component: loadView('auth/Docs'),
            meta: {
                title: 'Buckit documentation',
                group: ['auth', 'supervisor', 'admin'],
            },
        },
        {
            path: '/email-bevestigen',
            name: 'email-confirmation',
            beforeEnter: guardAuth,
            component: loadView('guest/EmailConfirm'),
            meta: {
                title: 'E-mail bevestiging | Buckit',
                group: ['guest'],
            },
        },
        {
            path: '/registreren',
            name: 'register',
            beforeEnter: guardAuth,
            component: loadView('guest/Register'),
            meta: {
                title: 'Registreren | Buckit',
                group: ['guest'],
            },
        },
        {
            path: '/login',
            name: 'login',
            beforeEnter: guardAuth,
            component: loadView('guest/Login'),
            meta: {
                title: 'Login | Buckit',
                group: ['guest'],
            },
        },
        {
            path: '/logout',
            name: 'logout',
            beforeEnter: guardAuth,
            meta: {
                title: 'logout | Buckit',
                group: ['logout'],
            },
        },
        {
            path: '/splash',
            name: 'splash',
            redirect: (to: any) => {
                const isAdmin = store.getters['user/isAdmin'];
                const isSupervisor = store.getters['user/isSupervisor'];
                const isUser = store.getters['user/isUser'];

                if (isAdmin) {
                    return { name: 'companies' };
                }
                if (isSupervisor) {
                    return { name: 'companies' };
                }
                if (isUser) {
                    return { name: 'overview' };
                }

                return { name: 'login' };
            },
        },
        {
            path: '/wachtwoord-vergeten',
            name: 'forgot-password',
            beforeEnter: guardAuth,
            component: loadView('guest/ForgotPassword'),
            meta: {
                title: 'Wachtwoord vergeten | Buckit',
                group: ['guest'],
            },
        },
        {
            path: '/wachtwoord-reset',
            name: 'reset-password',
            beforeEnter: guardAuth,
            component: loadView('guest/ResetPassword'),
            meta: {
                title: 'Wachtwoord instellen | Buckit',
                group: ['guest'],
            },
        },
        {
            path: '/privacyverklaring',
            name: 'privacy-policy',
            beforeEnter: guardAuth,
            component: loadView('legal/PrivacyPolicy'),
            meta: {
                title: 'Privacy Policy | Buckit',
                group: ['guest', 'auth', 'supervisor', 'admin'],
            },
        },
        {
            path: '/algemene-voorwaarden',
            name: 'disclaimer',
            beforeEnter: guardAuth,
            component: loadView('legal/Disclaimer'),
            meta: {
                title: 'Algemene Voorwaarden | Buckit',
                group: ['guest', 'auth', 'supervisor', 'admin'],
            },
        },
        {
            path: '/cookiebeleid',
            name: 'cookie-policy',
            beforeEnter: guardAuth,
            component: loadView('legal/CookiePolicy'),
            meta: {
                title: 'Cookiebeleid | Buckit',
                group: ['guest', 'auth', 'supervisor', 'admin'],
            },
        },
        {
            path: '/overzicht',
            name: 'overview',
            beforeEnter: guardAuth,
            component: loadView('auth/Overview'),
            meta: {
                title: 'Overzicht | Buckit',
                group: ['auth'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    const user = store.getters['user/user'];

                    next();

                    const promises = [
                        store.dispatch('product/overview', { company_slug: user.company_collection_slug }),
                        store.dispatch('collection/indexCategories'),
                        store.dispatch('company/read', { id: user.company_id }),
                        store.dispatch('order/mostRecent', user.company_id),
                    ];

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },
        {
            path: '/profiel',
            name: 'profile',
            beforeEnter: guardAuth,
            component: loadView('auth/Profile'),
            meta: {
                title: 'Profiel | Buckit',
                group: ['auth', 'supervisor', 'admin'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    const company_id = store.getters['user/user'].company_id;

                    next();

                    const promises = [];

                    if (company_id != null) {
                        promises.push(store.dispatch('company/read', { id: company_id }));
                    }

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },
        {
            path: '/producten',
            name: 'products',
            beforeEnter: guardAuth,
            component: loadView('auth/products/Index'),
            meta: {
                title: 'Producten | Buckit',
                group: ['auth'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    const user = store.getters['user/user'];

                    next();

                    const promises = [store.dispatch('product/orderList', { company_slug: user.company_collection_slug, collection_slug: to.query.category, q: to.query.q }), store.dispatch('collection/index')];

                    if (window.location.hash && window.location.hash.substring(1) !== 'contact') {
                        promises.push(store.dispatch('product/read', { slug: window.location.hash.substring(1) }));
                    }

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },
        {
            path: '/bestellingen',
            name: 'orders',
            beforeEnter: guardAuth,
            component: loadView('auth/orders/Index'),
            meta: {
                title: 'Bestellingen | Buckit',
                group: ['auth'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    const company_id = store.getters['user/user'].company_id;

                    const promises = [store.dispatch('order/index', { companyId: company_id })];

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },
        {
            path: '/bestellen',
            name: 'create-order',
            beforeEnter: guardAuth,
            component: loadView('auth/orders/Create'),
            meta: {
                title: 'Bestelling plaatsen | Buckit',
                group: ['auth', 'supervisor'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    const isAdmin = store.getters['user/isAdmin'];
                    const isSupervisor = store.getters['user/isSupervisor'];
                    let companyId = null;

                    if (isAdmin || isSupervisor) {
                        companyId = store.getters['company/viewing'].id;
                    } else {
                        companyId = store.getters['user/user'].company_id;
                    }

                    const promises = [store.dispatch('company/read', { id: companyId }), store.dispatch('company/readCart', { id: companyId })];

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },
        {
            path: '/contact',
            name: 'contact',
            beforeEnter: guardAuth,
            component: loadView('auth/Contact'),
            meta: {
                title: 'Contact | Buckit',
                group: ['auth'],
                beforeResolve(to: any, from: any, next: any) {
                    next();
                },
            },
        },

        /*
        | -----------------
        | SUPERVISOR
        | -----------------
        | Supervisor pages.
        */
        {
            path: '/bedrijven',
            name: 'companies',
            beforeEnter: guardAuth,
            component: loadView('supervisor/Companies'),
            meta: {
                title: 'Bedrijven | Buckit',
                group: ['supervisor'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    const promises = [store.dispatch('company/index')];

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },
        {
            path: '/bedrijven/:id',
            name: 'company-detail',
            beforeEnter: guardAuth,
            component: loadView('supervisor/Company'),
            meta: {
                title: 'Bedrijf | Buckit',
                group: ['supervisor'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    store
                        .dispatch('company/read', { id: to.params.id })
                        .then((company: Company) => {
                            const collectionId = company.collection_id;

                            const promises = [store.dispatch('product/index'), store.dispatch('order/index', { companyId: to.params.id }), store.dispatch('account/indexUsersInCompany', { companyId: to.params.id })];

                            if (collectionId) {
                                promises.push(store.dispatch('product/orderListById', { id: collectionId }));
                                promises.push(store.dispatch('collection/read', { id: collectionId }));
                            } else {
                                promises.push(store.dispatch('product/setOrderList', []));
                            }

                            Promise.all(promises).then(
                                () => {
                                    store.dispatch('stopLoadingPage');
                                },
                                (err: ErrorResponse) => {
                                    handleUnauthorized(err, next);
                                },
                            );
                        })
                        .catch((err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        });
                },
            },
        },
        {
            path: '/alle-producten',
            name: 'all-products',
            beforeEnter: guardAuth,
            component: loadView('supervisor/AllProducts'),
            meta: {
                title: 'Alle producten | Buckit',
                group: ['supervisor'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    const promises = [store.dispatch('product/index'), store.dispatch('collection/index')];

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },
        {
            path: '/alle-bestellingen',
            name: 'all-orders',
            beforeEnter: guardAuth,
            component: loadView('supervisor/AllOrders'),
            meta: {
                title: 'Alle bestellingen | Buckit',
                group: ['supervisor'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    const promises = [store.dispatch('order/index')];

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },

        /*
        | -----------------
        | ADMIN
        | -----------------
        | Admin pages.
        */
        {
            path: '/supervisors',
            name: 'supervisors',
            beforeEnter: guardAuth,
            component: loadView('admin/Supervisors'),
            meta: {
                title: 'Supervisors | Admin | Buckit',
                group: ['admin'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    const promises = [store.dispatch('account/indexSupervisors')];

                    Promise.all(promises).then(
                        () => {
                            store.dispatch('stopLoadingPage');
                        },
                        (err: ErrorResponse) => {
                            handleUnauthorized(err, next);
                        },
                    );
                },
            },
        },
        {
            path: '/instellingen',
            name: 'admin-settings',
            beforeEnter: guardAuth,
            component: loadView('admin/Settings'),
            meta: {
                title: 'Instellingen | Admin | Buckit',
                group: ['admin'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    store.dispatch('stopLoadingPage');
                },
            },
        },
        {
            path: '/gebruikers/:id',
            name: 'user-detail',
            beforeEnter: guardAuth,
            component: loadView('admin/User'),
            meta: {
                title: 'Gebruikersprofiel bewerken | Admin | Buckit',
                group: ['admin'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    store.dispatch('stopLoadingPage');
                },
            },
        },

        /*
        | -----------------
        | ERROR PAGES
        | -----------------
        | Show the general error pages
        */
        {
            path: '/errors/:code',
            name: 'error',
            component: loadView('errors/general'),
            meta: {
                title: 'Errors | Buckit',
                group: ['supervisor'],
                beforeResolve(to: any, from: any, next: any) {
                    store.dispatch('startLoadingPage');

                    next();

                    store.dispatch('stopLoadingPage');
                },
            },
        },
    ],
});

router.beforeResolve(async (routeTo: any, routeFrom: any, next: any) => {
    try {
        if (routeFrom.meta && routeFrom.meta.leaveCallback) {
            await routeFrom.meta.leaveCallback(routeTo, routeFrom);
        }

        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                if (route.meta && route.meta.title) {
                    document.title = route.meta.title;
                }
                if (route.meta && route.meta.group) {
                    // @ts-ignore
                    document.querySelector('body').classList.add(route.meta.group);
                }

                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args: any) => {
                        if (args.length) {
                            next(...args);
                            reject(new Error('Redirected'));
                        } else {
                            resolve({ success: true });
                        }
                    });
                } else {
                    resolve({ success: true });
                }
            });
        }
    } catch (error) {
        return;
    }

    next();
});

/**
 * Guard guest middleware
 * Redirect to the login page when the user is not logged in
 *
 * @param {to} string The route the user was comming from
 * @param {from} string The route the user is going to
 * @param {next} function The next route funciton
 */
async function guardAuth(to: any, from: any, next: any) {
    try {
        const auth: Auth = await store.dispatch('user/me');

        if (auth && auth.id) {
            await store.dispatch('user/permissions');

            const isAdmin = store.getters['user/isAdmin'];
            const isSupervisor = store.getters['user/isSupervisor'];
            const isUser = store.getters['user/isUser'];

            if (to.meta.group) {
                if ((to.meta.group.includes('auth') && isUser) || (to.meta.group.includes('supervisor') && (isSupervisor || isAdmin)) || (to.meta.group.includes('admin') && isAdmin)) {
                    next();
                } else if (
                    (to.meta.group.includes('guest') && !['forgot-password', 'reset-password'].includes(to.name)) ||
                    (to.meta.group.includes('auth') && !isUser) ||
                    (to.meta.group.includes('supervisor') && (!isSupervisor || !isAdmin)) ||
                    (to.meta.group.includes('admin') && !isAdmin)
                ) {
                    return next({ name: 'splash' });
                }
            }
        }

        if (to.meta.group && to.meta.group.some((r: string) => ['auth', 'supervisor', 'admin'].includes(r)) && (!auth || !auth.id)) {
            return next({ name: 'login' });
        }

        if (to.meta.group && to.meta.group.includes('logout')) {
            await store.dispatch('reset');
            await store.dispatch('user/logout');

            return next({ name: 'login' });
        }

        next();
    } catch (e) {
        if (to.name === 'home') {
            next({ name: 'login' });
        }

        if (to.meta.group && to.meta.group.includes('guest')) {
            return next();
        }

        next({ name: 'login' });
    }
}

function loadView(view: string) {
    return () => lazyLoadView(import(`@/views/${view}.vue`));
}

/**
 * Lazy-loads view components, but with better UX. A loading view
 * will be used if the component takes a while to load, falling
 * back to a timeout view in case the page fails to load. You can
 * use this component to lazy-load a route with:
 *
 * component: () => lazyLoadView(import('@views/my-view'))
 *
 * @param {AsyncView} The view component
 */
function lazyLoadView(AsyncView: any) {
    const AsyncHandler = () => ({
        component: AsyncView,
        delay: 400,
        timeout: 10000,
    });

    return Promise.resolve({
        functional: true,
        render(h: CreateElement, { data, children }: any) {
            return h(AsyncHandler, data, children);
        },
    });
}

function handleUnauthorized(error: ErrorResponse, next: any) {
    if (error.status === 401) {
        next({ name: 'error', params: { code: '401' } });
    }
}

export default router;
