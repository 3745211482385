import { Module, ActionTree, MutationTree, GetterTree } from 'vuex';

const initialState: AccountState = {
    all: [],
    viewing: undefined,
};

const getters: GetterTree<AccountState, RootState> = {
    all(state: AccountState): Auth[] {
        return state.all;
    },
};

const mutations: MutationTree<AccountState> = {
    RESET(state: AccountState) {
        state.all = [];
    },
    ADD_MODEL(state: AccountState, payload: Auth) {
        state.all.push(payload);
    },
    SET_MODELS(state: AccountState, payload: Auth[]) {
        state.all = payload;
    },
    UPDATE_MODEL(state: AccountState, payload: Auth) {
        const index = state.all.findIndex((model: Auth) => model.id === payload.id);

        if (index === -1) {
            state.all.push(payload);
        } else {
            state.all[index] = payload;
        }
    },
    REMOVE_MODEL(state: AccountState, payload: Auth) {
        state.all = state.all.filter((model: Auth) => model.id !== payload.id);
    },
    SET_VIEWING(state: AccountState, payload: Auth) {
        state.viewing = payload;
    },
};

const resource: string = 'accounts';

const actions: ActionTree<AccountState, RootState> = {
    clearViewing({ commit }) {
        commit('SET_VIEWING', undefined);
    },
    create({ commit, rootState }, payload: Auth) {
        return rootState.api
            .post(`${resource}`, payload, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('ADD_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    index({ commit, rootState }) {
        return rootState.api
            .get(`${resource}`, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexAdmins({ commit, rootState }) {
        commit('RESET');
        return rootState.api
            .get(`${resource}?q[and][role_names][]=administrator`, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexSupervisors({ commit, rootState }, payload?: { after?: Array<string | number>; q?: string; no_set?: boolean }) {
        if (!payload || (payload && !payload.hasOwnProperty('no_set'))) {
            commit('RESET');
        }
        return rootState.api
            .get(`${resource}?q[and][role_names][]=supervisor${payload && payload.q ? `&q[and][or][first_name][]=like:*${payload.q}*&q[and][or][first_name][]=match:${payload.q}&q[and][or][last_name][]=like:*${payload.q}*&q[and][or][last_name][]=match:${payload.q}` : ''}`, { withCredentials: true })
            .then((response: { data: Auth }) => {
                if (!payload || (payload && !payload.hasOwnProperty('no_set'))) {
                    commit('SET_MODELS', response.data);
                }

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    indexUsersInCompany({ commit, rootState }, payload: { companyId: number; after?: Array<string | number>; q?: string }) {
        commit('RESET');
        return rootState.api
            .get(`${resource}?q[and][company_id]=${payload.companyId}${payload.q ? `&q[and][or][first_name][]=like:*${payload.q}*&q[and][or][first_name][]=match:${payload.q}&q[and][or][last_name][]=like:*${payload.q}*&q[and][or][last_name][]=match:${payload.q}` : ''}`, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('SET_MODELS', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    read({ commit, rootState }, payload: { id: number; no_set?: boolean }) {
        return rootState.api
            .get(`${resource}/${payload.id}`, { withCredentials: true })
            .then((response: { data: Auth }) => {
                if (!payload.hasOwnProperty('no_set')) {
                    commit('UPDATE_MODEL', response.data);

                    commit('SET_VIEWING', response.data);
                }
                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    update({ commit, rootState }, payload: Auth) {
        return rootState.api
            .put(`${resource}/${payload.id}`, payload, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('UPDATE_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
    delete({ commit, rootState }, payload: { id: number }) {
        return rootState.api
            .delete(`${resource}/${payload.id}`, { withCredentials: true })
            .then((response: { data: Auth }) => {
                commit('REMOVE_MODEL', response.data);

                return Promise.resolve(response.data);
            })
            .catch((e: ErrorResponse) => {
                return Promise.reject(e);
            });
    },
};

export default {
    namespaced: true,
    state: initialState,
    getters,
    actions,
    mutations,
};
