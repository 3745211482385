import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import axios from 'axios';
import modules from './modules';

Vue.use(Vuex);

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});

const debug = process.env.NODE_ENV !== 'production';
const store: StoreOptions<RootState> = {
    state: {
        version: process.env.VUE_APP_VERSION,
        api,
        page_loading: false,
        server_errors: { status: 0, errors: [] },
    },
    modules,
    strict: debug,
    getters: {
        pageLoading(state: RootState): boolean {
            return state.page_loading;
        },
        getErrors(state: RootState): ErrorResponse {
            return state.server_errors;
        },
    },
    mutations: {
        PAGE_LOADING(state: RootState) {
            state.page_loading = true;
        },
        PAGE_LOADED(state: RootState) {
            state.page_loading = false;
        },
        CLEAR_ERRORS(state: RootState) {
            state.server_errors = { status: 0, errors: [] };
        },
        SET_ERRORS(state: RootState, payload: ErrorResponse) {
            state.server_errors = payload;
        },
    },
    actions: {
        reset({ commit }: any) {
            const promises: any = [];
            Object.keys(modules).forEach((moduleName: string) => {
                commit(`${moduleName}/RESET`);
                promises.push(Promise.resolve());
            });

            return Promise.all(promises).then(() => Promise.resolve());
        },
        startLoadingPage({ commit }: any) {
            commit(`PAGE_LOADING`);
        },
        stopLoadingPage({ commit }: any) {
            commit(`PAGE_LOADED`);
        },
        clearErrors({ commit }: any) {
            commit(`CLEAR_ERRORS`);
        },
        setErrors({ commit }: any, payload: ErrorResponse) {
            commit(`SET_ERRORS`, payload);
        },
    },
};

const rootStore = new Vuex.Store<RootState>(store);

api.interceptors.request.use(
    async (config) => {
        rootStore.dispatch('clearErrors');
        return config;
    },
    (err) => Promise.reject(err));

api.interceptors.response.use(
    (res) => res.data,
    (err) => {
        if (err.response.hasOwnProperty('errors')) {

            rootStore.dispatch('setErrors', err.response.data);
            throw err.response.data;
        }

        const errorResponse: ErrorResponse = {
            status: err.response.status,
            errors: [],
        };

        if (!err.response.data.hasOwnProperty('errors')) {
            const error: Error = {
                name: 'server_error',
                message: 'server_error',
            };
            errorResponse.errors.push(error);

            rootStore.dispatch('setErrors', errorResponse);
            throw errorResponse;
        }

        errorResponse.errors = err.response.data.errors;

        rootStore.dispatch('setErrors', errorResponse);
        throw errorResponse;
    },
);

export default rootStore;
